<template>
    <div>
        <card-header title="Edit Room Type" icon="fa-pencil"/>

        <card-body>
            <room-type-form :room="room"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteRoom"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Room Type?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Room Type</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import EventForm from "./EventForm";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {client as http} from "../../http_client";
    import AnswerForm from "@/cards/trips/AnswerForm";
    import RoomTypeForm from "./RoomTypeForm";

    export default {
        components: {RoomTypeForm, ContainerListItem, SubheaderListItem, CardList, EventForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                room: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadRoom: function () {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId + '/rooms/' + this.props.roomId, {force: true}).then(response => {
                    this.room = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId + '/rooms/' + this.props.roomId, this.room, {force: true}).then(response => {
                    this.$reloadCard('trip-hotel', {hotelId: this.props.hotelId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteRoom() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId + '/rooms/' + this.props.roomId).then(response => {
                        this.$reloadCard('trip-hotel', {hotelId: this.props.hotelId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.answerId': function () {
                this.dirty = null;
                this.loadRoom();
            },
            'answer': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this room type? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadRoom();
        }
    };
</script>

<style scoped>

</style>
